import React from "react";
import Header from "../_components/Header";
import Button from "../_components/Button";
import Footer from "../_components/Footer";
import ImageHero from "./image1.jpg";
import "react-html5video/dist/styles.css";
import "./PreguntasPage.css";
import { FormattedMessage } from "react-intl";
import Media from "react-media";
import HeaderMobile from "../_components/HeaderMobile";
import Accordion from "react-bootstrap/Accordion";

const faqs = [
  {
    title: "¿A que tengo derecho con mi inscripción?",
    text: (
      <p>
          Los derechos de inscripción incluyen: Derecho de participación en la prueba, Avituallamiento líquido y sólido, Maillot conmemorativo del evento (en caso de participar los 3 días), Servicio mecánico en llegadas etapas, pasta party en las etapas 2 y 3, finisher en caso de lograrlo.
      </p>
    ),
  },
  {
    title: "¿Cuántas etapas tiene la prueba?",
    text: (
      <p>
          La prueba consta de 3 etapas. Una crono nocturna de 3 km y dos etapas maraton (entre 60 y 80 km).
      </p>
    ),
  },
  {
    title: "¿Existen puntos de corte en el recorrido?",
    text: (
      <p>
        Si. En cada una de las etapas habrá un tiempo limite para acabarlas y se
        establecerán puntos de control de paso. Sereis informados con antelación
        de estos tiempos de corte.
      </p>
    ),
  },
  {
    title: "¿Está el recorrido flechado?",
    text: (
      <p>
        EL RECORRIDO SERÁ MARCADO EN SU TOTALIDAD. Se recomienda a todos/as los
        participantes que dispongan de GPS para poder seguir también el track
        enviado por la organización y así evitar problemas con falta de marcas o
        cualquier otro tipo de percance.
      </p>
    ),
  },
  {
    title: "¿Qué pasa si no finalizo una etapa?",
    text: (
      <p>
          Cualquier participante que oficialmente no completa una etapa, queda fuera de la misma, obteniendo en tiempo del último clasificado para poder sumarlo en la clasificación general. Podrá salir el día siguiente con el tiempo que le corresponda.
      </p>
    ),
  },
  {
    title: "¿Qué pasa si abandona mi compañer@?",
    text: (
      <p>
          En ese caso, se tomará el tiempo del último participante y al día siguiente podrán salir como pareja (en caso de que el compañero pueda) o en individual (si el compañero no puede salir por difentes razones).
      </p>
    ),
  },
  {
    title: "¿La prueba es abierta al tráfico?",
    text: (
      <p>
        SI, ASI ES. La prueba está abierta al tráfico por lo que se deberá
        respetar semáforos, cruces, modo de circulación, etc. La organización no
        se responsabiliza de los posibles percances acaecidos por no respetar la
        circulación y sus normas, pudiendo ser expulsados de la prueba en caso
        de que se vea a algún participantes cometiendo una infracción.
      </p>
    ),
  },
  {
    title: "¿Es obligatorio llevar móvil en la prueba?",
    text: (
      <p>
        Recomendamos llevar móvil en la prueba. para que la organización pueda
        ponerse en contacto si surge algún imprevisto o hay algún problema.
        Importante que tenga batería suficiente.
      </p>
    ),
  },
  {
    title: "¿Cuándo puedo retirar el dorsal?",
    text: (
      <p>
        Se establecerá con tiempo suficiente el lugar y horas de entrega de
        dorsales.
      </p>
    ),
  },
  {
    title: "¿Puede retirar el dorsal algún compañero por mí?",
    text: (
      <p>
        Sí, siempre que tenga DNI y Licencia de la federación, y los papeles que
        se deben tener cubiertos para la prueba.
      </p>
    ),
  },
  {
    title: "¿Tengo que pagar el seguro federativo?",
    text: (
      <p>
          SOLO si no tienes la licencia Federativa en Vigor. La federación española tiene la opción de LICENCIA DE DÍA para el evento, que en el caso de la Costa Atlantica MTB Tour es de 36 euros por las 3 etapas y de 12€ si participas sólo en la etapa del Domingo.
      </p>
    ),
  },
  {
    title: "¿Qué hay en los avituallamientos?",
    text: (
      <p>
        En los avituallamientos se dispondrá de: Avituallamiento sólido/líquido
        ( para los participantes): fruta variada, frutos secos, agua, bebida
        isotónica, coca cola, pastelitos, etc.
      </p>
    ),
  },
  {
    title: "¿Es obligatorio recoger el trofeo si lo gano?",
    text: (
      <p>
        SI. Es obligatorio. En cada una de las etapas, los líderes de la general
        y de cada categoría, recibirán el maillot de líder y SERÁ OBLIGATORIO,
        que corran con dicho maillot en la siguiente etapa. Los ganadores
        finales también deben estar en el pódium para la recogida de premios el
        último dia de la prueba.
      </p>
    ),
  },
  {
    title: "¿Hace falta dispositivo GPS?",
    text: (
      <p>
        No es preciso para realizar la prueba, aunque se recomienda a todos/as
        los participantes que dispongan de GPS para poder seguir también el
        track enviado por la organización y así evitar problemas con falta de
        marcas o cualquier otro tipo de percance.
      </p>
    ),
  },
  {
    title: "¿Si no voy se devuelve la cuota que he pagado?",
    text: (
      <p>
        No hay devolución económica salvo que se contrate el SEGURO DE
        DEVOLUCIÓN y se cumplan las clausulas establecidas en el mismo. Es
        importante revisar dichas clausulas y condiciones del SEGURO DE
        DEVOLUCIÓN.
      </p>
    ),
  },
];

class PreguntasPage extends React.Component {
  render() {
    return (
      <div className="Embassador">
        <Media query={{ minWidth: 768 }}>
          {(matches) => (matches ? <Header /> : <HeaderMobile />)}
        </Media>
        <section
          className="Embassador-hero"
          style={{
            height: "150px",
            width: "100%",
          }}
        ></section>
        <section className="Embassador-info">
          <div className="container-fluid">
            <div className="row py-5">
              <div className="col-8 offset-2">
                <h4 className="participantes-title">Preguntas frecuentes</h4>
                <Accordion defaultActiveKey="0">
                  {faqs.map((faq, index) => {
                    const { title, text } = faq;
                    return (
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>{title}</Accordion.Header>
                        <Accordion.Body>{text}</Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <Media query={{ minWidth: 768 }}>
          {(matches) =>
            matches ? (
              ""
            ) : (
              <section className="inscription-fixed-bar">
                <Button
                  className="inscription-fixed"
                  href="https://sportmaniacs.com/es/services/inscription/costa-atlantica-mtb-2024"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Inscríbete
                </Button>
              </section>
            )
          }
        </Media>
      </div>
    );
  }
}

export default PreguntasPage;
