import React from 'react';
import './Footer.css';
import image1 from "./image1.jpg";
import image2 from "./image2.jpg";
import image3 from "./image3.jpg";

class Footer extends React.Component {
  render() {
    return (
      <footer id="footer" className="footer">
    <div className="row no-gutters">
      <div className="col-12 col-md-4">
        <div className="footer-image-wrapper">
          <img
            className="img-fluid"
            src={image1}
            alt="image"
            style={{ height: 400 + "px" }}
          />
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="footer-image-wrapper">
          <img
            className="img-fluid"
            src={image2}
            alt="image"
            style={{ height: 400 + "px" }}
          />
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="footer-image-wrapper">
          <img
            className="img-fluid"
            src={image3}
            alt="image"
            style={{ height: 400 + "px" }}
          />
        </div>
      </div>
    </div>
    <div className="row no-gutters">
      <div className="col-12">
        <p className="copyright">2019. GlobalDXT S.L.</p>
      </div>
    </div>
  </footer>
    );
  }
}

export default Footer;