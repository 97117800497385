import React from "react";
import leftArrow from "./leftArrow.svg";

const LeftArrow = props => {
  return (
    <div className="left-arrow" onClick={props.goToPrevSlide}>
      <img src={leftArrow} alt="arrow" />
    </div>
  );
};

export default LeftArrow;
