import React from "react";

const Slide = ({ image }) => {
  const styles = {
    display: "inline-block",
    height: "600px",
    width: "100%",
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 60%"
  };
  return <div className="slide" style={styles} />;
};

export default Slide;
