import image1 from "./images/image1.jpg";
import image2 from "./images/image2.jpg";
import image3 from "./images/image3.jpg";
import image4 from "./images/image4.jpg";
import image5 from "./images/image5.jpg";
import image6 from "./images/image6.jpg";
import image7 from "./images/image7.jpg";
import image8 from "./images/image8.jpg";
import image9 from "./images/image9.jpg";
import image10 from "./images/image10.jpg";
import image11 from "./images/image11.jpg";
import image12 from "./images/image12.jpg";
import image13 from "./images/image13.jpg";
import image14 from "./images/image14.jpg";
import image15 from "./images/image15.jpg";
import image16 from "./images/image16.jpg";
import image17 from "./images/image17.jpg";
import image18 from "./images/image18.jpg";
import image19 from "./images/image19.jpg";
import image20 from "./images/image20.jpg";
import image21 from "./images/image21.jpg";
import image22 from "./images/image22.jpg";
import image23 from "./images/image23.jpg";
import image24 from "./images/image24.jpg";
import image25 from "./images/image25.jpg";
import image26 from "./images/image26.jpg";
import image27 from "./images/image27.jpg";
import image28 from "./images/image28.jpg";
import image29 from "./images/image29.jpg";
import image30 from "./images/image30.jpg";
import image31 from "./images/image31.jpg";
import image32 from "./images/image32.jpg";
import image33 from "./images/image33.jpg";
import image34 from "./images/image34.jpg";
import image35 from "./images/image35.jpg";
import image36 from "./images/image36.jpg";
import image37 from "./images/image37.jpg";
import image38 from "./images/image38.jpg";
import image39 from "./images/image39.jpg";
import image40 from "./images/image40.jpg";

const images2019 = [
  { src: image1, width: 830, height: 645 },
  { src: image2, width: 830, height: 645 },
  { src: image3, width: 830, height: 645 },
  { src: image4, width: 830, height: 645 },
  { src: image5, width: 830, height: 645 },
  { src: image6, width: 830, height: 645 },
  { src: image7, width: 830, height: 645 },
  { src: image8, width: 830, height: 645 },
  { src: image9, width: 830, height: 645 },
  { src: image10, width: 830, height: 645 },
  { src: image11, width: 830, height: 645 },
  { src: image12, width: 830, height: 645 },
  { src: image13, width: 830, height: 645 },
  { src: image14, width: 830, height: 645 },
  { src: image15, width: 830, height: 645 },
  { src: image16, width: 830, height: 645 },
  { src: image17, width: 830, height: 645 },
  { src: image18, width: 830, height: 645 },
  { src: image19, width: 830, height: 645 },
  { src: image20, width: 830, height: 645 },
  { src: image21, width: 830, height: 645 },
  { src: image22, width: 830, height: 645 },
  { src: image23, width: 830, height: 645 },
];

export default images2019;
