import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import ImageHero from './image1.jpg';
import Alda from './alda.png';
import Ponteloft from "./ponteloft.png";
import 'react-html5video/dist/styles.css';
import './HostingPage.css';
import { FormattedMessage } from 'react-intl';
import Media from "react-media";
import HeaderMobile from '../_components/HeaderMobile';

class HostingPage extends React.Component {
  render() {
    return (
      <div className="Hosting">
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              <Header />
            ) : (
              <HeaderMobile />
            )}
        </Media>
        <section className="Hosting-hero"
          style={{
            height: '150px',
            width: '100%',
          }}>
        </section>
        <section className="Hosting-info">
          <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h4 className="paquetes-title">Alojamiento</h4>
              <div className="row py-5">
                <div className="col-12 col-sm-6">
                  <article className="text-center d-flex flex-column justify-content-center align-items-center">
                    <figure><img src={Alda} width="300" alt="hotel"/></figure>
                    <p className="mt-auto">
                      Información:{" "}
                      <a
                        className="Hosting-web"
                        href="https://www.aldahotels.es/alojamientos/hotel-alda-estacion-pontevedra/"
                      >
                        Hotel Alda - Pontevedra
                      </a>
                    </p>
                  </article>
                </div>
                <div className="col-12 col-sm-6">
                  <article className="text-center d-flex flex-column justify-content-center align-items-center">
                    <figure><img src={Ponteloft} width="300" alt="hotel"/></figure>
                    <p className="mt-auto">
                      Información:{" "}
                      <a
                        className="Hosting-web"
                        href="https://ponteloft.com/"
                      >
                        Ponteloft - Apartamentos turísticos
                      </a>
                    </p>
                  </article>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>
        <Footer/>
        <Media query={{minWidth: 768}}>
          {matches =>
            matches ? (
              ''
            ) : (
              <section className="inscription-fixed-bar">
                <Button className="inscription-fixed"
                        href="https://sportmaniacs.com/es/services/inscription/costa-atlantica-mtb-2024" target="_blank"
                        rel="noopener noreferrer">
                  Inscríbete
                </Button>
              </section>
            )}
        </Media>
      </div>
    );
  }
}

export default HostingPage;
